import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme"
import {Grid} from '@material-ui/core';
import { Link, graphql } from 'gatsby';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all'
import { throttle } from 'lodash';

import ProgressBarVert from '../utils/progressBar-step-vert';

import Context from '../context';

gsap.registerPlugin(ScrollToPlugin)



const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
`

const TitleCond = styled.h5`
  position: fixed;
  left: 15%;
  top: 50px;
  font-size: 3rem;
  font-weight: 300;
  width: 400px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  text-align: left;
  /* background-color: ${props => props.theme.colors.dark}; */
  z-index: 100;

  @media ${props => props.theme.breaks.down('md')} {
    top: 100px;
    left: 10%;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2rem;
    top: 80px;
  }
`

const PortfolioSection = styled.div`
  /* min-height: 100vh;
  padding-top: 50px;
  text-align: center;
  padding-bottom: 350px;
  position: relative; */

  @media ${props => props.theme.breaks.down('sm')} {
    padding-top: 80px;
  }
`

const BarContainer = styled.div`
  height: 400px;
  width: 2rem;
  position: fixed;
  right: 10%;
  top: 30%;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

// const Content = styled.div`
//   width: 70%;
//   margin: 0 auto;
//   margin-top: 80px;
//   /* padding-top: 50px; */
// `

const FullHeight = styled.div`
  height: 100vh;
  padding-top: 10%;

  @media ${props => props.theme.breaks.down('lg')} {
    padding-top: 15%;
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding-top: 20%;
  }
  @media ${props => props.theme.breaks.down('sm')} {
    height: auto;
    padding-top: 50px;
  }
`

const Logo = styled.div`
  background-image: url(${props => props.image.src});
  min-height: 350px;
  height: 75%;
  max-height: 550px;
  width: 70%;
  margin: 0 auto;
  background-position: center;
  background-size: cover;
  position: relative;
/* 
  &:first-child {
    margin-top: 0;
  } */

  .gradient {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgb(50, 48, 49);
    opacity: .45;
    transition: opacity .3s;

    &:hover {
      opacity: .8;
    }

  }

  .small-logo {
      width: 15%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      opacity: 1;
    }

  @media ${props => props.theme.breaks.down('md')}  {
    width: 100%;
    height: 450px;

    .gradient {
      opacity: .8;
    }

    .small-logo {
        width: 35%;
      }
  }

  @media ${props => props.theme.breaks.down('sm')}  {
    height: 250px;
  }
  
`

const FixedFooter = styled.div`
  height: 100px;
  width: 40%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  padding-left: 40px;
  position: fixed;
  background-color: transparent;
  bottom: 1rem;

  @media ${props => props.theme.breaks.down('md')}  {
    bottom: 0;
    padding-left: 0;
  }
`

const PreFetch = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: -1;
  height: 1px;
  width: 1px;
`


const PortfolioPage = ({ data }) => {

  const {edges} = data.allStrapiPortfolios

  const [counter, setCounter] = useState(Context.portNum);

  const mouseHandler = (id, imgC) => {
    document.getElementById(id).src = imgC
  }

  const mouseOutHandler = (id, img) => {
    document.getElementById(id).src = img
  }

  let number = Context.portNum;

  const scroll = (num, e, count) => {
    if (e.deltaY > 0) {
      if (num < count - 1) {
        gsap.to(window, {scrollTo: `.logo-${num + 1}`, duration: .6})
        Context.portNum += 1
        setCounter(x => x + 1)
        num = Math.min(num + 1, count - 1)
      } 
    } else {
      if (num > 0) {
        gsap.to(window, {scrollTo: `.logo-${num - 1}`, duration: .6})
        Context.portNum -= 1
        setCounter(x => x - 1)
        num = Math.max(num - 1, 0)
      } 
    }
    return num;
  }
  
    useEffect(() => {


      if (window.innerWidth < 600) {
      edges.forEach(({node}, i) => {
        document.getElementById(node.name).src = node.logoColorized.childImageSharp.fluid.src
      });
    }
  
      const scroller = throttle((e) => {
        number = scroll(number, e, edges.length)
     
    }, 800, {trailing: false})
    
      window.addEventListener("wheel", scroller)
  
      return () => window.removeEventListener("wheel", scroller)
  
    }, [])


  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout color={theme.colors.body} pageTitle="Our previous work">
      <Container>

        <TitleCond>the stories we've told</TitleCond>
        
        <PortfolioSection className="portfolio-sec">

          <PreFetch>
            {edges.map(({node}, i) => <img key={`pre-${node.name}`} src={node.logoColorized.childImageSharp.fluid.src}></img>)}
          </PreFetch>
          
          <BarContainer>
            <ProgressBarVert count={edges.length || 0} current={counter} bg="#6E6E6E" color={theme.colors.primary}></ProgressBarVert>
          </BarContainer>

            {edges.sort((a, b) => a.node.num - b.node.num).map(({node}, i) => <FullHeight className={`logo-${i}`} key={node.name} ><Logo onMouseOver={() => mouseHandler(node.name, node.logoColorized.childImageSharp.fluid.src)} onMouseOut={() => mouseOutHandler(node.name, node.logo.childImageSharp.fluid.src)} image={node.backgroundImage.childImageSharp.fluid}><Link to={node.portfolioPath}><div className="gradient"></div><img className="small-logo" id={node.name} src={node.logo.childImageSharp.fluid.src} alt="logo" /></Link></Logo></FullHeight>
            )}


           
        </PortfolioSection>

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>

      </Container>
      </Layout>
    
)
}

export default PortfolioPage;

export const query = graphql`
  query {
  allStrapiPortfolios {
    edges {
      node {
        name
        num
        portfolioPath: gatsbyPath(filePath: "/portfolios/{StrapiPortfolios.name}")
        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logo {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoColorized {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}

`